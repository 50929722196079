<template>
  <div class="c-contentsFaq">
    <ul
      v-if="ServiceFaq.length"
      class="c-questions"
    >
      <li
        v-for="(faq, index) in ServiceFaq"
        :key="`faq${index}`"
        class="c-questions_item"
      >
        <contents-faq-detail :faq="faq" />
      </li>
    </ul>
  </div>
</template>
<script>
// local components
import ContentsFaqDetail from './ContentsFaqDetail'

export default {
  name: 'ContentsFaq',
  components: {
    ContentsFaqDetail
  },
  props: {
    ServiceFaq: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
.c-questions {
  border-top: 1px solid $ds2-color-gray-50;
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-questions {
    border-top: 0;
  }
}
</style>
