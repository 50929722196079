<template>
  <div class="c-contentsFaqDetail">
    <a
      @click="expanded = !expanded"
      class="c-question"
      v-if="faq.question"
    >
      <div class="c-question_icon">
        <div class="c-question_iconInner">
          <img
            src="@/assets/images/pages/services/question-icon.svg"
            loading="lazy"
            decoding="async"
            alt="question"
          />
        </div>
      </div>
      <div class="c-question_text">
        <div v-text="faq.question"></div>
      </div>
      <div class="c-question_readMore">
        <span v-show="isPC || isTB">{{ $t('components.contents_faq_detail.see_answer') }}</span>
        <coconala-icon
          :name="expanded ? 'chevron-up' : 'chevron-down'"
          size="11px"
          color="ds2Gray600"
        />
      </div>
    </a>
    <div
      class="c-answer"
      v-if="expanded"
    >
      <div class="c-answer_icon">
        <img
          src="@/assets/images/pages/services/answer-icon.svg"
          loading="lazy"
          decoding="async"
          alt="answer"
        />
      </div>
      <div class="c-answer_text">
        <div v-text="faq.answer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ContentsFaqDetail',
  props: {
    faq: {
      type: Object,
      default: () => ({
        answer: '',
        question: ''
      })
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    ...mapGetters('ui', ['isPC', 'isTB'])
  }
}
</script>
<style lang="scss" scoped>
.c-contentsFaqDetail {
  border-bottom: 1px solid $ds2-color-gray-50;
}

.c-question {
  @include link-effect;

  display: flex;
  padding: 16px 0;
  color: $ds2-color-gray-900;

  &_icon {
    flex: 0 0 31px;
    margin-right: 8px;

    align-self: flex-start;
  }

  &_iconInner {
    display: flex;
    width: 31px;
    height: 25px;

    i {
      margin: 0 auto;

      align-self: center;
    }
  }

  &_text {
    flex: 1 1 auto;
    white-space: pre-wrap;
    word-wrap: break-word;

    align-self: flex-start;
  }

  &_readMore {
    flex: 0 0 100px;
    width: 100px;
    color: $ds2-color-gray-600;
    text-align: right;

    align-self: flex-start;
  }
}

.c-answer {
  display: flex;
  padding: 0 110px 16px 0;
  color: $ds2-color-gray-900;

  &_icon {
    flex: 0 0 31px;
    margin-right: 8px;

    align-self: flex-start;
  }

  &_iconInner {
    display: flex;
    width: 31px;
    height: 25px;

    i {
      margin: 0 auto;

      align-self: center;
    }
  }

  &_text {
    flex: 1 1 auto;
    white-space: pre-wrap;
    word-wrap: break-word;

    align-self: flex-start;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-question {
    padding: 16px 12px;
  }

  .c-answer {
    padding: 0 110px 16px 12px;
  }
}

@media (max-width: breakpoint(ServiceDetail, S)) {
  .c-question {
    &_readMore {
      flex: 0 0 20px;
      width: 20px;
    }
  }

  .c-answer {
    padding: 0 12px 16px;
  }
}
</style>
